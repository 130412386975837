@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NanumSquareNeoBold";
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-cBd.eot);
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-cBd.eot?#iefix)
      format("embedded-opentype"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-cBd.woff)
      format("woff"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-cBd.ttf)
      format("truetype");
}

@font-face {
  font-family: "NanumSquareNeoExtraBold";
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-dEb.eot);
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-dEb.eot?#iefix)
      format("embedded-opentype"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-dEb.woff)
      format("woff"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-dEb.ttf)
      format("truetype");
}

@font-face {
  font-family: "NanumSquareNeoHeavy";
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-eHv.eot);
  src: url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-eHv.eot?#iefix)
      format("embedded-opentype"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-eHv.woff)
      format("woff"),
    url(https://hangeul.pstatic.net/hangeul_static/webfont/NanumSquareNeo/NanumSquareNeoTTF-eHv.ttf)
      format("truetype");
}

@font-face {
  font-family: "GmarketSansMedium";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "goorm-sans-bold";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/2408@1.0/goorm-sans-bold.woff2")
    format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "BMJUA";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMJUA.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BMDOHYEON";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMDOHYEON.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BMEULJIRO";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.0/BMEULJIRO.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "EF_jejudoldam";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2210-EF@1.0/EF_jejudoldam.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
@import url("//fonts.googleapis.com/earlyaccess/jejugothic.css");
@import url("//fonts.googleapis.com/earlyaccess/jejumyeongjo.css");
@import url("//fonts.googleapis.com/earlyaccess/jejuhallasan.css");
body {
  overflow-x: hidden;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.MuiDataGrid-root ::-webkit-scrollbar {
  display: block !important;
  width: 8px;
  height: 8px;
}

.MuiDataGrid-root ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.MuiDataGrid-root ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.MuiDataGrid-root ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* MUI dialog 타이틀 가운데정렬 */
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  text-align: center;
}

/*특정 부분 스크롤바 없애기*/

.box {
  -ms-overflow-style: none;
}
.box::-webkit-scrollbar {
  display: none;
}

ul,
li {
  list-style: none;
}

a {
  color: inherit; /* 상위 엘리먼트의 색상 상속 */
  text-decoration: none;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.canvas-container {
  z-index: 999;
}

.overlaybox {
  position: relative;
  width: 96px;
  height: 96px;

  background: url(./assets/cloud.png) no-repeat;
  background-size: contain;
}

.overlaybox .title {
  font-size: 0.7rem;
  font-weight: 550;
  padding-top: 0.7rem;
  text-align: center;
  width: 80px;
  color: #efefef;
}

.overlaybox .find {
  font-size: 0.7rem;
  font-weight: 600;
  padding-top: 0.6rem;
  text-align: center;
  width: 80px;
  color: rgb(179, 179, 255);
}

.overlaybox .find:hover {
  text-decoration: underline;
  cursor: pointer;
}

.ql-editor {
  min-height: 200px; /* 원하는 높이로 설정하세요 */
}

.ql-editor * {
  cursor: auto;
}

.ql-editor a {
  cursor: pointer;
}

.p-button {
  font-size: 12px !important;
}

.btn {
  padding: 11px 22px;
  border: none;
  border-radius: 8px;
  background-color: #f2f4f6;
  color: #4e5968;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
  background-color: #3282f6;
  color: #f9fcff;
  width: 80%;
  display: block;
  margin: 0 auto 20px auto;
}

.custom-label {
  font-size: 12px !important;
  color: #666;
}

.slick-dots li.slick-active button:before {
  color: #3282f6 !important;
}

.slick-dots li button:before {
  color: #3282f6 !important;
}

.css-zzms1-MuiSnackbar-root {
  top: 10% !important;
}

/* .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
  width: 36px !important;
  height: 36px !important;
} */

.css-1hpqosy-MuiFormControlLabel-root .MuiFormControlLabel-label {
  width: 55px;
  text-align: center;
}
